<!-- loginType 登录类型 -->
<template>
  <div class="loginType zm-flex is-center " v-if="hasWxLogin">
    <div class="" @click="handelLogin">
      <!-- <div class="mb10"></div> -->
      <img class="icon-img" src="@/assets/img/icon-wChat.png" alt="" />
      <div>点击一键登录</div>
    </div>
  </div>
</template>

<script>
// import { handleWx, getEvn } from '@/utils/handleEvn.js'
import {loc} from '@/utils/auth';
import * as tools from '@/utils/src/tools';

import {wxFirstLogin} from '@/utils/src/utilsWeixin';
export default {
  name: 'LoginType',
  props: {
    agreeCheck: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    hasWxLogin() {
      //   const evn = getEvn()
      //   const flag = evn === 'wx'
      return true;
    }
  },
  methods: {
    // 初始化方法
    init() {},
    handelLogin() {
      if (!this.agreeCheck) {
        this.$emit('check-agree');
        return;
      }
      if (tools.os.isWechat) {
        wxFirstLogin();
      } else {
        this.wxLoginFn();
      }
    },
    wxLoginFn() {
      let self = this;
      getService();

      // 微信授权登录对象
      let aweixin = null;
      // 当前环境支持的所有授权登录对象
      let auths = null;

      // 获取登录授权认证服务列表，单独保存微信登录授权对象
      function getService() {
        window.plus.oauth.getServices(
          function(services) {
            console.log(JSON.stringify(services));
            // window.plus.nativeUI.alert("services:" + JSON.stringify(services));
            auths = services;
            authLogin();
          },
          function(e) {
            window.plus.nativeUI.alert('获取登录授权服务列表失败，请稍后重试');
          }
        );
      }

      // 获取微信登录授权对象后可进行登录认证操作
      function authLogin() {
        for (let i = 0; i < auths.length; i++) {
          if (auths[i].id == 'weixin') {
            aweixin = auths[i];
            break;
          }
        }
        if (!aweixin) {
          window.plus.nativeUI.alert('当前环境不支持微信登录');
          return;
        }
        console.log(' aweixin.login', aweixin);
        if (!aweixin.authResult) {
          aweixin.login(
            function(e) {
               self.$toast.success('认证成功');
              authUserInfo();
            },
            function(e) {
              window.plus.nativeUI.alert(JSON.stringify(e));
               self.$toast.success('认证失败');
            }
          );
        } else {
          console.log('已经登录认证!');
          authUserInfo();
        }
      }

      // 获取微信登录授权对象后获取用户信息操作
      function authUserInfo() {
        self.$toast.loading({
          mask: true,
          message: '微信登录中...'
        });

        if (!aweixin) {
          self.$toast.clear();
          window.plus.nativeUI.alert('当前环境不支持微信登录');
          return;
        }
        if (aweixin.authResult) {
          aweixin.getUserInfo(
            function(e) {
              // 登录成功处理
              self.$toast.clear();
              loc.set('wxUserInfo', aweixin.userInfo);
              console.log('wxUserInfo', JSON.stringify(aweixin.userInfo));
              self.$emit('auth-suc');
              authLoginOut(); // 注销登录防止切换账号获取到旧信息
            },
            function(e) {
              console.log('获取用户信息失败： ' + JSON.stringify(e));
            }
          );
        } else {
          self.$toast.clear();
          window.plus.nativeUI.alert('未登录认证!');
        }
      }

      // 注销登录认证
      function authLoginOut() {
        if (!aweixin) {
          window.plus.nativeUI.alert('当前环境不支持微信登录');
          return;
        }
        aweixin.logout(
          function(e) {
            // window.plus.nativeUI.alert("注销登录认证成功!" + JSON.stringify(e));
          },
          function(e) {
            window.plus.nativeUI.alert("注销登录认证失败!" + JSON.stringify(e));
          }
        );
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.loginType {
  // margin-top: 1rem;
  font-size: 0.26rem;
  color: #38c44c;
  .icon-img {
    width: 0.88rem;
    height: 0.88rem;
  }
}
</style>
